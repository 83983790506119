var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.areaGridClass },
    [
      _c("p", { staticClass: "label", class: _vm.labelGridClass }, [
        _vm.bulkAddErrors.length > 0
          ? _c("span", [
              _c("img", {
                staticClass: "w-4 h-4 inline-block",
                attrs: { src: require("@/assets/image/alert_icon.svg") },
              }),
            ])
          : _vm._e(),
        _vm._v(" " + _vm._s(_vm.displayLabel) + " "),
      ]),
      _c(
        "p",
        {
          staticClass: "field flex justify-start flex-wrap content-around",
          class: _vm.fieldGridClass,
        },
        [
          _vm.isEdit
            ? [
                _c("InputGenreField", {
                  staticClass: "w-full",
                  attrs: {
                    fieldId: _vm.fieldId,
                    rules: _vm.rules,
                    is_main: _vm.isMainGenre,
                    errors: _vm.inputErrors.genres,
                  },
                  model: {
                    value: _vm.editGenre,
                    callback: function ($$v) {
                      _vm.editGenre = $$v
                    },
                    expression: "editGenre",
                  },
                }),
                _c("p", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        "genres" in _vm.inputErrors && _vm.inputErrors.genres,
                      expression:
                        "'genres' in inputErrors && inputErrors.genres",
                    },
                  ],
                  staticClass: "text-xxs text-left text-red-600 mt-1",
                }),
              ]
            : [
                _vm._l(_vm.displayGenres, function (genre, key) {
                  return [
                    _c(
                      "div",
                      {
                        key: key,
                        staticClass:
                          "py-1 px-2 mr-2 mb-2 genre-tag-label text-sm text-white",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(genre.translations[_vm.$i18n.locale].name) +
                            " "
                        ),
                      ]
                    ),
                  ]
                }),
              ],
        ],
        2
      ),
      _vm.bulkAddErrors.length > 0
        ? _vm._l(_vm.bulkAddErrors, function (error, key) {
            return _c(
              "p",
              {
                key: key,
                staticClass:
                  "col-start-2 col-span-5 text-xs text-left text-red-600 mt-1",
              },
              [_vm._v(" " + _vm._s(error) + " ")]
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }